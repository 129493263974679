@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */


#editor-caption-word:empty::before {
  /* content: attr(data-placeholder); */
  content: "•";
  /* text-decoration: line-through;
  color: gray;
  display: hidden; */
} 

:root {
  --caption-stroke-color: #000;
  --caption-fill-color: #fff;
  --caption-highlight-color: yellow;
  --caption-font-weight: 900;
  --caption-case: uppercase;
}


@layer base {
  .text-stroke {
    color: var(--caption-fill-color);
    font-weight: var(--caption-font-weight);
    text-transform: var(--caption-case);
    /* font-size: calc(var(--caption-font-size) * 1px); */
  }

  .text-stroke:before {
    content: attr(data-text);
    -webkit-text-stroke: .2em var(--caption-stroke-color);
    /* text-shadow: 0.15ch 0.15ch 0 var(--caption-stroke-color), -0.1ch -0.1ch 0 var(--caption-stroke-color), 0.1ch -0.1ch 0 var(--caption-stroke-color), -0.1ch 0.1ch 0 var(--caption-stroke-color), 0.1ch 0.1ch 0 var(--caption-stroke-color); */
    position: absolute;
    color: var(--caption-stroke-color);
    height: 100%;
    width: 100%;
    z-index: -1;  
  }  
  .text-stroke:after {
    content: attr(data-text);
    -webkit-text-stroke: .2em var(--caption-stroke-color);
    /* text-shadow: 0.15ch 0.15ch 0 var(--caption-stroke-color), -0.1ch -0.1ch 0 var(--caption-stroke-color), 0.1ch -0.1ch 0 var(--caption-stroke-color), -0.1ch 0.1ch 0 var(--caption-stroke-color), 0.1ch 0.1ch 0 var(--caption-stroke-color); */
    position: absolute;
    left: .05em;
    top: .05em;
    color: var(--caption-stroke-color);
    height: 100%;
    width: 100%;
    z-index: -1;  
  }  

  .word-timecode:after {
    content: attr(data-start-minute) ' - ' attr(data-end-minute);
    display: none;
    position: absolute;
    left: 0;
    white-space: nowrap;
    font-size: 12px;
    top: 100%;
    background-color: black; 
    z-index: 9999; 
    padding: 0px 4px;
    pointer-events: none;
  }

  .word-timecode:hover:after {
    display: flex;
    flex-direction: row;
  }
}

/* #video-preview, #video-preview *:not(video):not(#blur-wrapper) {
  overflow: visible !important;
} */


/* TIMELINE */

/* Only show effect3 on hover (EmptyAction) */
.timeline-editor-action-effect-effect3 {
  opacity: 0;
}
.timeline-editor-action-effect-effect3:hover {
  opacity: 1;
}

.timeline-editor-action:not([data-width="0"]) {
  background-color: transparent !important;
  border-radius: 8px !important;
  overflow: hidden;
  border: 1px solid rgb(2 6 23 / var(--tw-bg-opacity)) !important;
}

.timeline-editor-action-right-stretch, .timeline-editor-action-left-stretch {
  border: none !important;
  border-radius: 0px!important;
  width: 4px !important;
  z-index: 9999;
}

.timeline-editor-action-effect-effect0 .timeline-editor-action-right-stretch, .timeline-editor-action-effect-effect0 .timeline-editor-action-left-stretch {
  background-color: rgb(168 85 247);
}

.timeline-editor-action-effect-effect1 .timeline-editor-action-right-stretch, .timeline-editor-action-effect-effect1 .timeline-editor-action-left-stretch {
  background-color: rgb(71 85 105);
}


.timeline-editor-action-right-stretch:after, .timeline-editor-action-left-stretch:after {
  border: none !important;
}

.timeline-editor-edit-row {
  background: transparent !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#clips-demo::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#clips-demo {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}